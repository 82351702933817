<template>
<div class="outer-wrapper">
    <div class="inner-wrapper">
        <div class="title-wrapper">
            <p class="title">{{data.title}}</p>
        </div>
        <Divider orientation="left">专业概要</Divider>
        <div class="content-wrapper">
            <div class="content">
                <p>{{data.description}}</p>
                
            </div>
            <div class="img">
                <img :src="data.img"/>
            </div>
        </div>
        <Divider orientation="left">项目专家</Divider>
        <Tabs>
        <TabPane :label="String(y)" v-for="y in yearList" :key="y" class="exe"><div class="expert-wrapper">
            <p class="expert-title">{{y}}年安评项目参与专家</p>
            <table class="expert">
                <tr>
                    <td>序号</td>
                    <td>姓名</td>
                    <td>职称/职务</td>
                    <td>参与专业</td>
                </tr>
                <tr v-for="(expert,index) in expertList.filter(val=>val.year===y)" :key="index+'ex'">
                    <td>{{index+1}}</td>
                    <td>{{expert.name}}</td>
                    <td>{{expert.title}}</td>
                    <td>{{expert.major}}</td>
                </tr>
                <tr>
                    <td>合计</td>
                    <td colspan="3">共{{expertList.filter(val=>val.year===y).length}}位</td>
                </tr>
                <tr>
                    <td colspan="4" class="left des"><span v-if="y===2019">注：参与本次安评项目评审的专家共9位，未统计在上述列表中。</span></td>
                </tr>
            </table>
        </div></TabPane>
    </Tabs>
    </div>
</div>
    
</template>

<script>
const expertData = {
    safe:{
        title:'安全管理',
        img:process.env.BASE_URL + "img/expert/image1.jpg",
        description:'安全管理评价主要从安全管理体系建设、安全管控落实情况和应急救援综合能力三个方面进行分析，其中包括安全管理机构设置、安全管理制度建设、人员安全制度落实情况、设备安全制度落实情况、事故风险隐患管理、事故应急救援6个分项。',
        expert:[
            {name:'邱毅骅',role:'安全工程师',major:'安全管理'},
            {name:'周斌生',role:'高级工程师',major:'安全管理'},
            ]
    },
    signal:{
        title:'信号专业',
        img:process.env.BASE_URL + "img/expert/image2.jpg",
        description:'信号系统评价包括信号系统和维修体系2个项目，其中包括ATC系统技术、安全防护措施、管理与维护、维修配件4个分项。',
        expert:[
            {name:'张琼燕',role:'教授级高级工程师',major:'信号'},
            {name:'虞翊',role:'教授',major:'信号'},
            ]
    },
    construction:{
        title:'土建专业',
        img:process.env.BASE_URL + "img/expert/image3.jpg",
        description:'土建系统评价分为线路及轨道评价和土建结构评价两部分，其中包含线路平、纵断面线形、车站结构以及隧道结构养护体系等42个分项。',
        expert:[
            {name:'李海锋',role:'教授',major:'土建'},
            ]
    },
    electricity:{
        title:'供电专业',
        img:process.env.BASE_URL + "img/expert/image4.jpg",
        description:'供电系统评价包括主变电站、牵引变电站、降压变电站、接触网、电力电缆和供电SCADA系统、杂散电流防护与监测系统7个项目。其中包括主变电站设备、主变电站安全防护设施、牵引变电设备、接触网设备等18个分项。',
        expert:[
            {name:'达世鹏',role:'高级工程师',major:'供电'},
            {name:'张明锐',role:'副教授',major:'供电'},
            ]
    },
    mechatronics:{
        title:'机电专业',
        img:process.env.BASE_URL + "img/expert/image5.jpg",
        description:'机电设备评价包括屏蔽门系统与防淹门系统、通风空调系统、给排水系统、电梯与自动人行道、风亭、低压配电系统、BAS系统、AFC系统、FAS系统、运行维护保养和应急处理保障 10个项目。',
        expert:[
            {name:'唐贾言',role:'高级工程师',major:'机电'},
            ]
    },
    communication:{
        title:'通信专业',
        img:process.env.BASE_URL + "img/expert/image6.jpg",
        description:'通信系统评价包括通信系统和维修体系2个项目，其中包括通信系统技术、传输系统、无线通信系统等12个分项。',
        expert:[
            {name:'戴孙放',role:'高级工程师',major:'通信'},
            {name:'陈洪',role:'教授级高级工程师',major:'通信'},
            ]
    },
    organization:{
        title:'运营组织',
        img:process.env.BASE_URL + "img/expert/image7.jpg",
        description:'运营组织与管理评价包括系统负荷、规章制度、客运组织、应急处置、人员培训与考核5个项目。其中包括线路负荷、车站负荷、安全宣传教育、人员培训、素质评价等13个分项。',
        expert:[
            {name:'蒲琪',role:'高级工程师',major:'运营组织'},
            ]
    },
    train:{
        title:'车辆专业',
        img:process.env.BASE_URL + "img/expert/image8.jpg",
        description:'车辆系统评价包括车辆、车辆维修体系2个项目。其中包括车辆安全性能与安全防护设施、车辆防火性能防火、车辆可靠性、维修人员、维修、检测设备、维修配件6个分项。',
        expert:[
            {name:'海帮君',role:'教授级高级工程师',major:'车辆'},
            {name:'朱小娟',role:'教授级高级工程师',major:'车辆'},
            ]
    },
    
    
}
export default{
    name: "expertMajor",
    components: {
    },
    props:{
        'major':{
            type:String,
            default:''
        }

    },
    data(){
        return {
            data:expertData[this.major],
            curIcon: process.env.BASE_URL + "img/icon7.png",
            expertList:[]

        };
    },
    created(){
        this.get_data();
    },
    computed: {
        yearList:function(){
        let tmp = [];
        for(let i of this.expertList){
            if(tmp.indexOf(i.year)===-1){
            tmp.push(i.year);
            }
        }
        tmp.sort();
        return tmp;
        },
    },
    methods:{
        get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/ask_for_expert", {
          major:expertData[this.major].title,
          userToken: userToken
        })
        .then(response => ((this.expertList = response.data.res_record)));
    },

    },
    

}
</script>

<style scoped>
.outer-wrapper{
    min-height: 500px;
    padding: 20px 150px;
}

.inner-wrapper{
    min-height:400px;
    text-align: center;
    padding: 0 100px;
}

.expert-title{
    height:50px;
    line-height:50px;
    font-size:18px;
}
table.expert{
    width:80%;
    margin:0 auto;
    border:3px double #999;
    box-shadow: 1px 2px 3px #eee;
    font-size:16px;
    border-collapse: collapse;
}

table.expert td{
    height:48px;
    border:1px solid #ddd;
}

.title-wrapper{
    height:50px;
    line-height: 50px;
}
.title{
    font-size:30px;
    font-weight:700;
}

.content-wrapper{
    min-height:300px;
    padding: 30px 0px;
    display: flex;
    justify-content:space-around;
}

.content{
    font-size:16px;
    text-align: left;
    text-indent: 32px;
    width:300px
}
.img img{
    width:350px;
    outline: 2px solid #999;
}
.left{
    text-align: left;
    padding: 0 20px;
}
.des{
    font-family: KaiTi;
    font-size:14px;
    font-weight:600;
}



</style>